<template>
  <div id="faq">
    <section class="section head">
      <div class="container">
        <h1>FAQ</h1>

        <span>Click a question to expand the answer.</span>
      </div>
    </section>

    <section class="section">
      <div class="categories container">
        <div class="category">
          <div class="questions">
            <div class="question" v-for="(question, j) in questions" :key="j">
              <input type="checkbox" :id="`q-${j + 1}`" />

              <div class="label" :id="`item-${j + 1}`">
                <div>{{ j + 1 }}. {{ question.question }}</div>

                <label :for="`q-${j + 1}`">
                  <md-icon>add_circle</md-icon>
                  <md-icon>remove_circle</md-icon>
                </label>
              </div>

              <div class="answer" v-html="question.answer"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Faq",
  metaInfo: function() {
    return {
      title: " - FAQs",
      meta: [
        {
          name: "description",
          content: "Click a question to expand the answer."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {};
  },
  computed: {
    questions: function() {
      return this.$store.state.faq.questions;
    }
  },
  mounted: function() {
    if (this.$route.params.question) {
      const i = this.$route.params.question;
      const container = document.getElementById(`item-${i}`);
      const checkbox = document.getElementById(`q-${i}`);
      checkbox.click();
      window.scrollTo({ top: container.offsetTop, behavior: "smooth" });
    }
  }
};
</script>

<style lang="scss">
#faq {
  .categories {
    .category {
      padding-bottom: 40px;

      .category-name {
        font-weight: bold;
        font-size: 1.65em;
        border-bottom: 1px solid #ccc;
        padding-bottom: 30px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      .questions {
        .question {
          border-bottom: 1px solid #ccc;
          margin-bottom: 15px;
          padding-bottom: 15px;

          input[type="checkbox"] {
            display: none;
          }

          .label {
            display: flex;
            justify-content: space-around;
            align-items: center;

            > div {
              width: 95%;
              flex: 0 0 95%;
              font-size: 1.3em;
            }
            label {
              cursor: pointer;

              .md-icon {
                color: $blue;

                &:last-child {
                  display: none;
                }
              }
            }
          }
          .answer {
            display: none;
            padding: 15px 8px 0;

            p {
              font-size: 1em;
            }
          }

          input:checked ~ .label {
            > div {
              font-weight: bold;
            }
            label .md-icon {
              &:first-child {
                display: none;
              }
              &:last-child {
                display: block;
              }
            }
          }
          input:checked ~ .answer {
            display: block;
          }
        }
      }
    }
  }
}
</style>
